import { taxonomies } from "~/models"

export const paddingOptions = (values: string[]) => {
    let classNames = ''
    if (values.length > 0) {
        for (const item of values) {
            if (item === 'no_top_padding') {
                classNames += ' pt-0'
            } else if (item === 'no_bottom_padding') {
                classNames += ' pb-0'
            }
        }
    }
    return classNames
}

export const backgroundOptions = (codename: string) => {
    if (!codename) return 'light';

    // the class will have the same name as the codename
    return codename;
}

export const textColorOptions = (codename:string) => {
    if (!codename) return 'primary_color'

    // the class will have the same name as the codename
    // can be: default, primary_color, red, black, green, yellow
    return codename;
}

